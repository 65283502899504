import { Injectable } from '@angular/core'
import { ApiService } from '@core/services/api/api.service'
import { DataService } from '@core/interfaces/data-service'
import { TableLazyLoadEvent } from 'primeng/table'
import { Observable, throwError } from 'rxjs'
import { Article } from '@core/models/shared/article/article.model'
import { ArticleStockDetail } from '@core/models/shared/article/article-stock-detail.model'
import { ArticleStock } from '@core/models/shared/article/article-stock.model'

@Injectable({ providedIn: 'root' })
export class ArticleService extends ApiService implements DataService {

  protected override endpoint: string = 'shared/articles'

  public getCollection(event: TableLazyLoadEvent): Observable<Article[]> {
    return this._getCollection<Article>(event)
  }

  public getCollectionSelect(filter: string, event: TableLazyLoadEvent | null = null): Observable<Article[]> {
    return this._getCollectionSelect<Article>(filter, event, true)
  }

  public getItem(id: number): Observable<Article> {
    return this._getItem<Article>(id)
  }

  public getTotalItems(): number {
    return this._getTotalItems()
  }

  public fetchStock(id: number): Observable<ArticleStock> {
    if (id <= 0)
      return throwError(() => new Error('Entity has invalid id'))

    const endpoint = `${this.endpoint}/${id}/stock`

    return this._get(endpoint, true)
  }

  public fetchCalculatedPurchasePrice(id: number, quantity: number) {
    if (id <= 0 || quantity <= 0)
      return throwError(() => new Error('Entity has invalid id or quantity'))

    const endpoint: string = `${this.endpoint}/${id}/purchase-price?quantity=${quantity}`

    return this._get(endpoint, true)
  }

  public create(entity: Article): Observable<Article> {
    if (entity.id && entity.id > 0)
      return this.update(entity)

    return this._post(entity)
  }

  public update(entity: Article): Observable<Article> {
    if (entity.id && entity.id > 0)
      return this._patch(entity.id, entity)

    return throwError(() => new Error('Entity has invalid id'))
  }

  public delete(entity: Article): Observable<any> {
    if (entity.id && entity.id > 0)
      return this._delete(entity.id)

    return throwError(() => new Error('Entity has invalid id'))
  }

  public getStockDetail(id: number, loadAll: boolean): Observable<ArticleStockDetail> {
    let url = '/stock-detail'
    if(loadAll) url += '?loadAll=true'

    return this._get(id + url, false, true)
  }

}
